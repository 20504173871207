import { push } from "connected-react-router";
import findIndex from "lodash/findIndex";
import pickBy from "lodash/pickBy";
import qs from "qs";
import { defineMessages } from "react-intl";
import { reset } from "redux-form";
import { combineEpics } from "redux-observable";

import { createAlert } from "alerts/redux/modules/alerts";
import axios from "axios";
import {
  inServiceArea,
  validateLocation,
} from "build/redux/modules/form/validation";
import { BOOTSTRAP_COMPLETE, updateJobData } from "build/redux/modules/manager";
import { fireBuildMetric } from "build/redux/modules/metrics";
import { setPagesAction } from "build/redux/modules/progress";
import { dispatchJobDraft } from "build/redux/modules/recommendations";
import { isIkeaJobFlow } from "build/redux/modules/selector";
import {
  FETCH_DRAFT_DATA,
  FETCH_IKEA_JOB_DATA,
  GENERAL_RABBIT_AVAILABILITY,
  IKEA_POST_SIMPLE_JOB_DRAFT,
  INCOMPLETE_DATA_REDIRECT,
  RABBIT_GRANULAR_AVAILABILITY_SHOW,
  UPDATE_IKEA_JOB,
  UPDATE_JOB_DRAFT,
} from "build/routes/apiRoutes";
import {
  getIkeaItemsFromJob,
  selectAssemblySeconds,
  selectItems,
  selectLocationHourlyRate,
} from "ikea/redux/modules/selector";
import {
  DetailsFormData,
  IkeaProduct,
  LegacyProductSection,
  ProductServiceItem,
  ScheduleFormData,
} from "ikea/types";
import { AnyAction } from "redux";
import { selectJobDraftGuid, selectJobLocation } from "shared/redux/selector";
import { Availability, JobDraft, Location } from "shared/types";
import { fireMetric } from "store/middleware/metricMiddleware";
import {
  IKEA_CATEGORIES,
  IKEA_DATA_COOKIE_NAME,
  IKEA_PRODUCTS,
  IKEA_RABBIT_ID,
  IKEA_TASK_TEMPLATE,
} from "util/constants";
import { getCookie, setCookie } from "util/cookie";
import { generateFunnelId } from "util/generateFunnelId";
import { ikeaPath } from "util/ikea";
import { internalPath } from "util/internalPath";
import storage from "util/localStorage";
import { format } from "util/money";
import { V3Epic, V3ReduxState, V3Thunk } from "util/reduxTypes";
import xhr, { createApiErrorAlert } from "util/xhr";

export { inServiceArea };

const messages = defineMessages({
  noStore: {
    id: "ikea.quote.error.no_store_selected",
    defaultMessage: "A store must be selected to continue",
  },
});
const sections = IKEA_PRODUCTS ? IKEA_PRODUCTS.sections : [];

export interface IkeaManagerState {
  sections: LegacyProductSection[];
  activeSection: string;
  items: IkeaProduct[];
  product_service_item?: ProductServiceItem;

  funnel_id?: string;
  form_referrer?: string;
  loading?: boolean;
  updating_in_progress?: boolean;

  job_draft?: JobDraft;
  job_draft_guid?: string;
  added_to_cart?: boolean;

  postalCode?: string;
  location?: Location;
  verifyingLocation?: boolean;

  storeKey?: string;
  isKiosk?: boolean;

  availability?: Availability;
  availabilityPayload?: unknown;
  blocks?: unknown;

  autoMatchDeclined?: boolean;
  autoMatchTaskerId?: number;

  // Job edit state
  job_id?: number;
  originalItems?: IkeaProduct[];
  jobDraftSource?: string;

  showPhaseMismatchModal?: boolean;
}

const initialState: IkeaManagerState = {
  sections,
  activeSection: sections.length ? sections[0].key : undefined,
  items: [],
};

const UPDATE_STATE_FROM_STORAGE =
  "v3/ikeaSimpleQuote/UPDATE_STATE_FROM_STORAGE";
const DATA_PERSIST_SUCCESS = "v3/ikeaSimpleQuote/DATA_PERSIST_SUCCESS";
const IKEA_STORAGE_ATTRIBUTE_KEY = "tr-ikea-post-data";
const IKEA_FORM_KEY = "tr-ikea-form";
const CHANGE_SECTION = "v3/ikeaSimpleQuote/CHANGE_SECTION";
const ADD_TO_QUOTE = "v3/ikeaSimpleQuote/ADD_TO_QUOTE";
const REMOVE_FROM_QUOTE = "v3/ikeaSimpleQuote/REMOVE_FROM_QUOTE";
const CHANGE_ITEM_QUANTITY = "v3/ikeaSimpleQuote/CHANGE_ITEM_QUANTITY";
const SET_STORE_NAME = "v3/ikeaSimpleQuote/SET_STORE_NAME";
const SET_JOB_DRAFT_SOURCE = "v3/ikeaSimpleQuote/SET_JOB_DRAFT_SOURCE";
const SET_POSTAL_CODE = "v3/ikeaSimpleQuote/SET_POSTAL_CODE";
export const RECEIVE_GEOCODE_RESPONSE =
  "v3/ikeaSimpleQuote/RECEIVE_GEOCODE_RESPONSE";
const SET_IKEA_CALENDAR_BLOCKS = "v3/ikeaSimpleQuote/SET_IKEA_CALENDAR_BLOCKS";
const SET_JOB_DRAFT = "v3/ikeaSimpleQuote/SET_JOB_DRAFT";
const RESET_QUOTE_FORM = "v3/ikeaSimpleQuote/RESET_QUOTE_FORM";
const CLICK_SUBMIT_QUOTE = "v3/ikeaSimpleQuote/CLICK_SUBMIT_QUOTE";
const PERFORM_UPDATE_ITEMS = "v3/ikeaSimpleQuote/PERFORM_UPDATE_ITEMS";
const PERFORM_UPDATE_ITEMS_RESPONSE =
  "v3/ikeaSimpleQuote/PERFORM_UPDATE_ITEMS_RESPONSE";
const PERFORM_UPDATE_ITEMS_ERROR =
  "v3/ikeaSimpleQuote/PERFORM_UPDATE_ITEMS_ERROR";
const QUOTE_SUBMITTED = "v3/ikeaSimpleQuote/QUOTE_SUBMITTED";
const QUOTE_SUBMITTED_ERROR = "v3/ikeaSimpleQuote/QUOTE_SUBMITTED_ERROR";
const SET_FUNNEL_ID = "v3/ikeaSimpleQuote/SET_FUNNEL_ID";
const SET_FORM_REFERRER = "v3/ikeaSimpleQuote/SET_FORM_REFERRER";
const SET_USER_ADDRESS = "v3/ikeaSimpleQuote/SET_USER_ADDRESS";
const LOAD_ITEMS_FROM_DRAFT = "v3/ikeaSimpleQuote/LOAD_ITEMS_FROM_DRAFT";
const UPDATE_AUTO_MATCH_DECLINED =
  "v3/ikeaSimpleQuote/UPDATE_AUTO_MATCH_DECLINED";
const SAVE_IKEA_AUTO_MATCH_TASKER =
  "v3/ikeaSimpleQuote/SAVE_IKEA_AUTO_MATCH_TASKER";
const WRITE_COOKIE_ACTIONS = [CHANGE_SECTION, SET_STORE_NAME];
const SET_PRODUCT_SERVICE_ITEM = "v3/productServices/SET_PRODUCT_SERVICE_ITEM";
const SET_PHASE_MISMATCH_MODAL_SHOW =
  "v3/ikeaSimpleQuote/SET_PHASE_MISMATCH_MODAL_SHOW";

const getItemTotalCost = (
  costDecimal: number,
  quantity: number
): string | undefined => {
  if (costDecimal === undefined) {
    return;
  }

  return format((costDecimal * quantity) / 100, {
    noCentsIfWhole: true,
  });
};

export default function ikeaManager(
  state: IkeaManagerState = initialState, // eslint-disable-line @typescript-eslint/default-param-last
  action: AnyAction // eslint-disable-line @typescript-eslint/default-param-last
): IkeaManagerState {
  switch (action.type) {
    case SET_FUNNEL_ID:
      return {
        ...state,
        funnel_id: action.funnel_id,
      };

    case SET_FORM_REFERRER:
      return {
        ...state,
        form_referrer: action.form_referrer,
      };

    case CHANGE_SECTION:
      return {
        ...state,
        activeSection: action.sectionKey,
      };

    case UPDATE_STATE_FROM_STORAGE:
      return {
        ...state,
        ...action.attributes,
      };

    case SET_JOB_DRAFT:
      return {
        ...state,
        job_draft: action.attributes,
        added_to_cart: false,
      };

    case ADD_TO_QUOTE: {
      const foundIndex = findIndex(
        state.items,
        (item) => item.ikea_id === action.item.ikea_id
      );

      const cartItem = state.items[foundIndex];
      const newItem = foundIndex === -1;
      const inputtedQuantity = action.item.quantity;
      const hasInputQuantity = inputtedQuantity > 0;

      let quantity = 1;

      if (newItem) {
        quantity = hasInputQuantity ? inputtedQuantity : 1;
      } else {
        quantity = hasInputQuantity
          ? cartItem.quantity + inputtedQuantity
          : cartItem.quantity + 1;
      }

      const totalCost = getItemTotalCost(action.item.assembly_price, quantity);
      const item = { ...action.item, quantity, total_cost: totalCost };

      return {
        ...state,
        items: newItem
          ? [...state.items, item]
          : [
              ...state.items.slice(0, foundIndex),
              item,
              ...state.items.slice(foundIndex + 1),
            ],
        added_to_cart: true,
      };
    }

    case REMOVE_FROM_QUOTE:
      return {
        ...state,
        items: [
          ...state.items.slice(0, action.index),
          ...state.items.slice(action.index + 1),
        ],
      };

    case CHANGE_ITEM_QUANTITY: {
      if (action.index > state.items.length) return state;
      const cost = state.items[action.index].assembly_price;
      let totalCost = state.items[action.index].total_cost;

      if (cost) {
        totalCost = getItemTotalCost(cost, action.quantity);
      }

      return {
        ...state,
        items: [
          ...state.items.slice(0, action.index),
          {
            ...state.items[action.index],
            quantity: action.quantity,
            total_cost: totalCost,
          },
          ...state.items.slice(action.index + 1),
        ],
      };
    }

    case LOAD_ITEMS_FROM_DRAFT:
      return {
        ...state,
        items: action.items,
        job_draft_guid: action.job_draft_guid,
      };

    case RESET_QUOTE_FORM:
      return {
        ...state,
        postalCode: undefined,
        location: undefined,
        items: [],
        funnel_id: undefined,
      };

    case SET_STORE_NAME:
      return {
        ...state,
        storeKey: action.storeKey,
        isKiosk: !!action.kiosk,
      };

    case SET_JOB_DRAFT_SOURCE:
      return {
        ...state,
        jobDraftSource: action.value,
      };

    case SET_POSTAL_CODE:
      return {
        ...state,
        postalCode: action.postalCode,
        verifyingLocation: true,
      };

    case SET_USER_ADDRESS:
      return {
        ...state,
        verifyingLocation: true,
      };

    case RECEIVE_GEOCODE_RESPONSE:
      return {
        ...state,
        location: action.value,
        verifyingLocation: false,
      };

    case SET_IKEA_CALENDAR_BLOCKS: {
      const {
        lastPayload,
        data: { blocks, availability },
      } = action;

      return {
        ...state,
        blocks,
        availability,
        availabilityPayload: lastPayload,
      };
    }

    case CLICK_SUBMIT_QUOTE:
      return {
        ...state,
        loading: true,
      };

    case QUOTE_SUBMITTED:
    case QUOTE_SUBMITTED_ERROR:
      return {
        ...state,
        loading: false,
      };

    case PERFORM_UPDATE_ITEMS:
      return {
        ...state,
        loading: true,
        updating_in_progress: true,
      };

    case PERFORM_UPDATE_ITEMS_ERROR:
    case PERFORM_UPDATE_ITEMS_RESPONSE:
      return {
        ...state,
        loading: false,
        updating_in_progress: false,
      };

    case UPDATE_AUTO_MATCH_DECLINED: {
      const { autoMatchDeclined } = action;
      return {
        ...state,
        autoMatchDeclined,
      };
    }

    case SAVE_IKEA_AUTO_MATCH_TASKER: {
      const { autoMatchTaskerId } = action;
      return {
        ...state,
        autoMatchTaskerId,
      };
    }

    case SET_PRODUCT_SERVICE_ITEM: {
      const { product_service_item } = action;
      return {
        ...state,
        product_service_item: product_service_item,
      };
    }

    case SET_PHASE_MISMATCH_MODAL_SHOW: {
      const { value } = action;
      return {
        ...state,
        showPhaseMismatchModal: value,
      };
    }

    default:
      return state;
  }
}

export const updateAutoMatchDeclinedState = (autoMatchDeclined: boolean) => ({
  type: UPDATE_AUTO_MATCH_DECLINED,
  autoMatchDeclined,
});

export const saveIkeaAutoMatchTasker = (autoMatchTaskerId: number) => ({
  type: SAVE_IKEA_AUTO_MATCH_TASKER,
  autoMatchTaskerId,
});

export const updateStateFromStorage = (
  { attributes }: { attributes?: Partial<IkeaManagerState> },
  persist = false
) => ({
  type: UPDATE_STATE_FROM_STORAGE,
  attributes,
  persist,
});

export const removeFromQuote = (index: number) => ({
  type: REMOVE_FROM_QUOTE,
  index,
  persist: true,
});

export const addItemToQuote = (item: IkeaProduct) => ({
  type: ADD_TO_QUOTE,
  item,
  persist: true,
});

export const setJobDraft = (attributes: JobDraft) => ({
  type: SET_JOB_DRAFT,
  attributes,
  persist: true,
});

export const changeSection = (sectionKey: string) => ({
  type: CHANGE_SECTION,
  sectionKey,
});

export const changeItemQuantity = (index: number, quantity: number) => ({
  type: CHANGE_ITEM_QUANTITY,
  index,
  quantity,
  persist: true,
});

export const setStore = (storeKey: string, kiosk = false) => ({
  type: SET_STORE_NAME,
  storeKey,
  kiosk,
});

export const receiveGeocodeResponse = (value: Location) => ({
  type: RECEIVE_GEOCODE_RESPONSE,
  value,
});

export const loadItemsFromDraft = (
  items: IkeaProduct[],
  jobDraftGuid: string
) => ({
  type: LOAD_ITEMS_FROM_DRAFT,
  items,
  job_draft_guid: jobDraftGuid,
});

export const resetQuote = () => ({
  type: RESET_QUOTE_FORM,
});

export const setFunnelId = (funnelId?: string) => ({
  type: SET_FUNNEL_ID,
  funnel_id: funnelId,
});

export const setFormReferrer = (formReferrer: string) => ({
  type: SET_FORM_REFERRER,
  form_referrer: formReferrer,
});

export const setPhaseMismatchModalShow = (value: boolean) => ({
  type: SET_PHASE_MISMATCH_MODAL_SHOW,
  value,
});

export const getIkeaStorage = ():
  | { attributes?: IkeaManagerState }
  | undefined => storage.get(IKEA_STORAGE_ATTRIBUTE_KEY);

export const setIkeaStorage = (attributes?: IkeaManagerState) =>
  storage.set(IKEA_STORAGE_ATTRIBUTE_KEY, { attributes });

export const setIkeaFormData = (attributes?: DetailsFormData & ScheduleFormData) =>
  storage.set(IKEA_FORM_KEY, { attributes });

export const getIkeaFormData = () => storage.get(IKEA_FORM_KEY);

export const getIkeaLegacyMetricData = (
  state: V3ReduxState,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>
) => {
  let query;
  if (state?.router?.location) {
    const { search } = state?.router?.location;
    query = qs.parse(search, { ignoreQueryPrefix: true });
  } else {
    query = {};
  }
  const {
    form_referrer: formReferrer,
    location,
    job_draft_guid: jobDraftGuid,
    job_draft: jobDraft,
    funnel_id: funnelId,
  } = state?.ikea?.manager;

  if (!data.job_draft_guid)
    data.job_draft_guid =
      query?.job_draft_guid || jobDraftGuid || jobDraft?.guid;
  if (!data.form_referrer)
    data.form_referrer = query?.form_referrer || formReferrer;
  if (!data.metro_id) {
    data.metro_id =
      location?.metro_id || jobDraft?.metro?.id || query?.metro_id;
    if (data.metro_id && typeof data.metro_id === "string")
      data.metro_id = parseInt(data.metro_id, 10);
  }
  const [categoryId] = IKEA_CATEGORIES;
  if (!data.category_id) data.category_id = categoryId;
  if (!data.task_template_id) data.task_template_id = IKEA_TASK_TEMPLATE;
  if (!data.funnel_id) data.funnel_id = funnelId;

  return data;
};

export const fireLocationErrorMetric =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (key: string, data: Record<string, any> = {}): V3Thunk =>
  (dispatch) => {
    dispatch(
      fireBuildMetric(key, {
        ...data,
        address_freeform: data.freeform,
      })
    );
  };

export const firePageViewedMetric =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (key: string, source: string, data: Record<string, any> = {}): V3Thunk =>
  (dispatch, getState) => {
    const state = getState();
    let query;
    if (state?.router?.location) {
      const { search } = state?.router?.location;
      query = qs.parse(search, { ignoreQueryPrefix: true });
    } else {
      query = {};
    }

    let { funnel_id: funnelId } = state.ikea.manager;

    if (!data.funnel_id) {
      if (!funnelId) {
        funnelId = (query?.funnel_id as string) || generateFunnelId();
        dispatch(setFunnelId(funnelId));
        data.source = source;
      }
      data.funnel_id = funnelId;
    }

    dispatch(fireMetric(key, getIkeaLegacyMetricData(state, data)));
  };

export const parseIkeaProducts = (ikeaProducts: IkeaProduct[] = []) => {
  const itemsMap = ikeaProducts.reduce(
    (map: Record<string, IkeaProduct>, item) => {
      if (map[item.ikea_id]) {
        map[item.ikea_id].quantity += 1;
      } else {
        map[item.ikea_id] = {
          ...item,
          quantity: 1,
        };
      }

      return map;
    },
    {}
  );

  return Object.values(itemsMap);
};

export const saveAutoMatchTasker = (): V3Thunk => (dispatch, getState) => {
  const state = getState();
  const { autoMatchTaskerId } = state.ikea.manager;
  const { invitee_id: taskerId } = state.build.manager.job;

  if (autoMatchTaskerId === undefined) {
    dispatch(saveIkeaAutoMatchTasker(taskerId));
  }
};

export const updateIkeaAutoMatchState =
  (taskerInfo: { userId: number }): V3Thunk =>
  (dispatch, getState) => {
    const state = getState();
    const { autoMatchTaskerId } = state.ikea.manager;
    const { userId: taskerId } = taskerInfo;

    if (!autoMatchTaskerId) {
      dispatch(updateAutoMatchDeclinedState(false));
    } else if (autoMatchTaskerId === taskerId) {
      dispatch(updateAutoMatchDeclinedState(false));
    } else if (autoMatchTaskerId !== taskerId) {
      dispatch(updateAutoMatchDeclinedState(true));
    }
  };

export const getExistingJobInformation =
  (jobId?: number): V3Thunk<Promise<void>> =>
  async (dispatch) => {
    if (!jobId) {
      return;
    }

    const url = FETCH_IKEA_JOB_DATA.replace(":id", jobId.toString());

    try {
      const { data } = await xhr.get(url);
      const { address: location } = data as { address: Location };

      const items = parseIkeaProducts(data.ikea_products?.items);

      if (Object.entries(location).length > 0) {
        dispatch(
          updateStateFromStorage(
            {
              attributes: {
                location,
                job_id: jobId,
                items,
                originalItems: items,
              },
            },
            true
          )
        );
      } else {
        throw new Error();
      }
    } catch {
      window.location = internalPath("/dashboard/active?items=0");
    }
  };

export async function getJobDraft(jobDraftGuid: string) {
  const res = await xhr.get<JobDraft>(FETCH_DRAFT_DATA, {
    params: { job_draft_guid: jobDraftGuid },
  });

  return res.data;
}

export const setLocationFromDraft =
  (): V3Thunk<Promise<void>> => async (dispatch, getState) => {
    // TODO: have to fetch location info again to get current price
    const state = getState();

    if (state.ikea.manager.location) {
      return;
    }

    const { search } = state.router.location;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    const jobDraftGuid = query.job_draft_guid ?? query.guid;

    if (!jobDraftGuid) {
      return;
    }

    try {
      const jobDraft = await getJobDraft(jobDraftGuid as string);
      const { location, category_id: categoryId } = jobDraft;
      const attributes = {
        location,
        ...(categoryId === IKEA_CATEGORIES[0] && {
          job_draft_guid: jobDraft.guid,
        }),
      };
      dispatch(updateStateFromStorage({ attributes }, true));
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== undefined && error.response.status >= 400 && error.response.status < 500) {
        window.location.href = INCOMPLETE_DATA_REDIRECT;
      } else {
        throw error;
      }
    }
  };

export const setJobDraftFromGuid =
  (jobDraftGuid: string): V3Thunk<Promise<void>> =>
  async (dispatch) => {
    if (!jobDraftGuid) {
      return;
    }

    const jobDraft = await getJobDraft(jobDraftGuid);

    dispatch(setJobDraft(jobDraft));
  };

const setLocationData = (
  payloadLocation: Location,
  managerLocation: Location | undefined
): { lat: number | null; lng: number | null } => {
  if (payloadLocation && payloadLocation.lat) {
    return { lat: payloadLocation.lat, lng: payloadLocation.lng };
  } else if (managerLocation && managerLocation.lat) {
    return {
      lat: Number(managerLocation.lat),
      lng: Number(managerLocation.lng),
    };
  }
  return { lat: null, lng: null };
};

export const fetchIkeaSchedule =
  (payloadLocation = {} as Location): V3Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    // For GM convergence flow, IKEA_RABBIT_ID is null
    if (!IKEA_RABBIT_ID) {
      dispatch({
        type: SET_IKEA_CALENDAR_BLOCKS,
        data: {},
      });

      return;
    }

    const { manager } = getState().ikea;
    const { location: managerLocation, job_draft, job_draft_guid } = manager;
    const location = setLocationData(payloadLocation, managerLocation);
    const hasPreferredTimeWindow = job_draft?.ikea_booked_time_windows?.length;
    const params = {
      task_template_id: IKEA_TASK_TEMPLATE,
      seconds_between: 0,
      invitee_id: IKEA_RABBIT_ID,
      location,
      force_legacy: true,
    };

    const url = hasPreferredTimeWindow
      ? GENERAL_RABBIT_AVAILABILITY
      : RABBIT_GRANULAR_AVAILABILITY_SHOW;
    const options = hasPreferredTimeWindow
      ? {
          params: {
            ...params,
            job_draft_guid,
            category_id: job_draft.category_id,
            country_iso_code: job_draft.country_iso_code,
          },
        }
      : { params };

    const { data } = await xhr.get(url, options);

    dispatch({
      type: SET_IKEA_CALENDAR_BLOCKS,
      lastPayload: params,
      data,
    });
  };

export const setJobDraftSource = (value: string | undefined) => ({
  type: SET_JOB_DRAFT_SOURCE,
  value,
});

export const setPostalCode =
  (postalCode: string, isQuote: boolean): V3Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch({
      type: SET_POSTAL_CODE,
      postalCode,
      isQuote,
    });

    const job = {
      category_id: IKEA_CATEGORIES[0], // needed to get proper IKEA service map
      funnel_id: getState().ikea.manager.funnel_id,
    };

    const { value } = await validateLocation({
      location: { freeform: postalCode },
      job,
      isQuote,
    });

    dispatch(receiveGeocodeResponse(value));
    await dispatch(fetchIkeaSchedule());
  };

const addressErrors = [
  "form.validation.geocode_failed",
  "form.validation.incomplete_address",
];

export const setUserAddress =
  (address: Partial<Location>): V3Thunk<Promise<Location>> =>
  async (dispatch, getState) => {
    dispatch({ type: SET_USER_ADDRESS });
    const state = getState();
    const {
      ikea: {
        manager: { job_draft: jobDraft },
      },
    } = state;

    const { value } = await validateLocation({
      location: address,
      job: {
        category_id: IKEA_CATEGORIES[0],
        funnel_id: getState().ikea.manager.funnel_id,
      },
    });

    dispatch(receiveGeocodeResponse(value));

    if (!value.error) {
      await dispatch(fetchIkeaSchedule());
      if (jobDraft?.guid) {
        dispatch(
          dispatchJobDraft({
            data: { job_draft_guid: jobDraft.guid, location: value },
          })
        );
      }
    } else if (addressErrors.includes(value.error)) {
      dispatch(
        fireBuildMetric("geocode_failed", {
          ...value,
          address_freeform: value.freeform,
        })
      );
    }

    if (isIkeaJobFlow(state)) {
      dispatch(updateJobData({ location: value }));
      dispatch(fireBuildMetric("ikea_select_items_viewed"));

      const nextPage = state.build.progress.page;
      const autoTaskerSelection = value.auto_tasker_selection_v2;
      dispatch(setPagesAction(state, nextPage, { autoTaskerSelection }));
    }

    return value;
  };

export const draftErrors = (): V3Thunk => (dispatch, getState) => {
  const { storeKey } = getState().ikea.manager;

  if (!storeKey)
    dispatch(
      createAlert({
        type: "error",
        messages: [messages.noStore.id],
      })
    );
};

interface QuoteData {
  email: string;
  mobile_phone: string;
  preferred_locale: string;
  consent_timestamp: number;
  order_number: string;
  coworker_id: string;
}

export const submitQuote =
  ({
    email,
    mobile_phone: mobilePhone,
    preferred_locale: preferredLocale,
    consent_timestamp: consentTimestamp,
    order_number: orderNumber,
    coworker_id: coworkerId,
  }: QuoteData): V3Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    dispatch({ type: CLICK_SUBMIT_QUOTE });

    const state = getState();
    const {
      items,
      storeKey: source,
      location = {},
      location: { lat, lng } = {},
      postalCode,
    } = state.ikea.manager;
    const ikeaProducts = items.reduce(
      (acc: string[], item) =>
        acc.concat(Array(item.quantity).fill(item.ikea_id)),
      []
    );

    const readyForSubmit =
      !!items.length && !!source && inServiceArea(location, true);

    if (!readyForSubmit) {
      dispatch(draftErrors());
      return;
    }

    const data = {
      attribution_tag: "taskrabbit_store_quote_webflow",
      category_id: IKEA_CATEGORIES[0],
      task_template_id: IKEA_TASK_TEMPLATE,
      email,
      mobile_phone: mobilePhone,
      order_number: orderNumber,
      coworker_id: coworkerId,
      preferred_locale: preferredLocale,
      consent_timestamp: consentTimestamp,
      source,
      ikea_products: ikeaProducts,
      location: { lat, lng },
      postal_code: postalCode,
      email_key: "simplified_quote_form",
    };
    try {
      await xhr.post(IKEA_POST_SIMPLE_JOB_DRAFT, data);

      dispatch({ type: RESET_QUOTE_FORM });
      dispatch({ type: QUOTE_SUBMITTED });
      // destroyOnUnmount redux-form option is set false (in case go back add items)
      // so issue this reset to remove email/mobile_phone values
      dispatch(reset("ikea_quote_confirm"));
      dispatch(push(ikeaPath("quote_thanks")));
      dispatch(
        fireMetric("simple_quote_job_draft_posted", {
          funnel_id: getState().ikea.manager.funnel_id,
          pageName: "IKEA In-store Quote Thank You Page",
        })
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch({ type: QUOTE_SUBMITTED_ERROR });
      dispatch(createApiErrorAlert(error));
    }
  };

export const loadCookie = (): V3Thunk => (dispatch) => {
  const cookie = getCookie(IKEA_DATA_COOKIE_NAME);
  const { activeSection: sectionKey, storeKey } = cookie
    ? JSON.parse(cookie)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    : ({} as any);

  if (sectionKey) {
    dispatch(changeSection(sectionKey));
  }
  if (storeKey) {
    dispatch(setStore(storeKey));
  }
};

export const setKioskMode =
  (queryString: string): V3Thunk =>
  (dispatch) => {
    const { kiosk, store } = qs.parse(queryString, {
      ignoreQueryPrefix: true,
    });
    if (!kiosk) {
      return;
    }

    dispatch(setStore(store as string, !!kiosk));
  };

export const updateItems =
  (): V3Thunk<Promise<void>> => async (dispatch, getState) => {
    dispatch({ type: PERFORM_UPDATE_ITEMS });
    const state = getState();
    const { items, job_id: jobId, location } = state.ikea.manager;

    if (!jobId) {
      return;
    }

    const ikeaProducts = items.reduce(
      (memo: string[], { ikea_id: ikeaId, quantity }) =>
        memo.concat(Array(quantity).fill(ikeaId)),
      []
    );

    const payload = {
      job_id: jobId,
      ikea_products: ikeaProducts,
      address: location,
    };

    try {
      const url = UPDATE_IKEA_JOB.replace(":id", payload.job_id.toString());
      await xhr.put(url, payload);
      dispatch({ type: PERFORM_UPDATE_ITEMS_RESPONSE });
      setIkeaStorage();
      window.location.href = internalPath("/dashboard/active?items=1");
    } catch (error) {
      setIkeaStorage();
      window.location.href = internalPath("/dashboard/active?items=0");
    }
  };

export const resetIkeaJobDraftProducts =
  (
    payload:
      | { category_id?: number; task_template_id?: number }
      | undefined = {}
  ): V3Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    const state = getState();
    const jobDraftGuid = selectJobDraftGuid(state);
    if (!jobDraftGuid) return;
    const updateURL = `${UPDATE_JOB_DRAFT.replace(":guid", jobDraftGuid)}`;

    const res = await xhr.put<JobDraft>(updateURL, {
      ikea_items: [],
      description: "",
      ...payload,
    });
    dispatch(setJobDraft(res.data));
    dispatch(setPhaseMismatchModalShow(true));
  };

const setCookieEpic: V3Epic = (action$, { getState }) =>
  action$
    .filter(({ type }) => WRITE_COOKIE_ACTIONS.includes(type))
    .do(() => {
      const { activeSection, storeKey } = getState().ikea;
      const val = JSON.stringify(pickBy({ activeSection, storeKey }, (i) => i));
      setCookie(IKEA_DATA_COOKIE_NAME, val, { expires: 99 });
    })
    .ignoreElements();

const preserveStateEpic: V3Epic = (action$, { getState }) =>
  action$
    .filter(({ persist }) => persist === true)
    .map(() => {
      const { manager } = getState().ikea;
      setIkeaStorage(manager);

      return { type: DATA_PERSIST_SUCCESS };
    });

const fireCartUpdateMetricEpic: V3Epic = (action$, { getState }) =>
  action$
    .ofType(ADD_TO_QUOTE, CHANGE_ITEM_QUANTITY, REMOVE_FROM_QUOTE)
    .map(() => {
      const state = getState();
      const { funnel_id: funnelId } = state.ikea.manager;
      const jobDraftGuid = selectJobDraftGuid(state);
      const metroId = selectJobLocation(state)?.metro_id;
      const items = selectItems(state);

      // Fire metric
      return fireMetric(
        "ikea_cart_updated",
        getIkeaLegacyMetricData(state, {
          funnel_id: funnelId,
          job_draft_guid: jobDraftGuid,
          est_metro_hourly_rate: selectLocationHourlyRate(state),
          est_total_time: selectAssemblySeconds(state),
          metro_id: metroId,
          items,
        })
      );
    });

const restoreSelectedProductsEpic: V3Epic = (action$, { getState }) =>
  action$
    .ofType(BOOTSTRAP_COMPLETE)
    .filter(() => isIkeaJobFlow(getState()))
    .map(() => {
      const items = getIkeaItemsFromJob(getState());

      return updateStateFromStorage({ attributes: { items } });
    });

export const managerEpic = combineEpics(
  preserveStateEpic,
  setCookieEpic,
  fireCartUpdateMetricEpic,
  restoreSelectedProductsEpic
);
